<template>
  <div dark>
    <v-img
      :src="require('@/assets/bulldog_bg.jpg')"
      class="grey lighten-2"
      height="300"
      width="100%"
    >
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          col="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          offset="1"
        >
          <h4 class="banner">
            <span>
              Your policy has not been renewed
            </span>
            We're sorry, theres been an error!
            <br>
            Please try again
          </h4>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
  export default {
    name: 'HomeBanner',
  }
</script>
